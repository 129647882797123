import React, { useState, useEffect } from "react";
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types'
import { useSearchParams } from "react-router-dom";

import PriceRanceFilter from './PriceRangeFilter';
import ShoeTypeFilter from './ShoeTypeFilter';
import SizeFilter from './SizeFilter';
import BrandFilter from './BrandFilter';
import BaseButton from "../../Buttons/BaseButton";
import ShowHideFiltersButton from "./ShowHideFiltersButton";
import FiltersGuideButton from "./FiltersGuideButton";

import useFirstRender from "../../../../hooks/useFirstRender";
import { getFilters } from '../../../../api';
import store from '../../../../store/store';
import { scrollToTop } from '../../../../helpers';

import { openPreloaderAction, closePreloaderAction, setSizesFilterAction, setBrandsFilterAction } from '../../../../store/actions';

import './style.scss'; 

function ProductFilters ({ getProductsApi, productType, filters, setFilters, setPage }) {
    let [searchParams, setSearchParams] = useSearchParams();

    const isMobile = window.screen.width < 768;

    const [isFiltersShown, setIsFiltersShown] = useState(true);

    const isFirstRender = useFirstRender();

    const [defaultMinPriceRange, setDefaultMinPriceRange] = useState(0);
    const [defaultMaxPriceRange, setDefaultMaxPriceRange] = useState(0);
    const [defaultSizes, setDefaultSizes] = useState([]);
    const [defaultBrands, setDefaultBrands] = useState([]);

    const [selectedFiltersCount, setSelectedFiltersCount] = useState(0);

    const showHideFilters = () => {
        const scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
        scrollToTop();

        if(!isFiltersShown) {
            setIsFiltersShown(true);
        } else if (isFiltersShown && scrollTop < 1200) {
            setIsFiltersShown(false);
        }
    }

    const clearFilters = () => {
        setPage(1);
        setFilters({
            selectedMinPriceRange: 0,
            selectedMaxPriceRange: 0,
            selectedSizes: [],
            selectedShoeType: '',
            selectedBrands: [],
            selectedPage: 1,
        });
    }

    useEffect(() => {
        isMobile && setIsFiltersShown(false);
    }, [productType]);

    useEffect(() => {
        store.dispatch(setSizesFilterAction(filters.selectedSizes));
    }, [filters.selectedSizes]);

    useEffect(() => {
        store.dispatch(setBrandsFilterAction(filters.selectedBrands));
    }, [filters.selectedBrands]);

    useEffect(() => {
        const { selectedMinPriceRange,
            selectedMaxPriceRange,
            selectedSizes,
            selectedShoeType,
            selectedBrands,
            selectedPage
        } = filters;
        
        const params = {};
        let filtersSelectedCount = 0;

        if (selectedMinPriceRange) {
            filtersSelectedCount = filtersSelectedCount + 2;
            params.minPriceRange = selectedMinPriceRange;
        };

        if (selectedMaxPriceRange) params.maxPriceRange = selectedMaxPriceRange;

        if (selectedShoeType) {
            filtersSelectedCount++;
            params.shoeType = selectedShoeType;
        };

        if (!isFirstRender) {
            filtersSelectedCount = filtersSelectedCount + selectedSizes.length;
            params.size = selectedSizes;
        };

        if (!isFirstRender) {
            filtersSelectedCount = filtersSelectedCount + selectedBrands.length;
            params.brand = selectedBrands
        };

        setSelectedFiltersCount(filtersSelectedCount);
        setSearchParams({...params, page: selectedPage});

        if(Object.keys(params).length !== 0) {
            const isScrollToTop = !isMobile;
            getProductsApi(selectedPage, 12, params, isScrollToTop);
            getFiltersApi(productType, params, !!selectedShoeType);
        };
    }, [filters]);

    const getFiltersApi = (productType, params, skipPriceRange) => {
        store.dispatch(openPreloaderAction());

        getFilters(productType, params)
            .then((data) => {
                const { 
                    sizes,
                    maxPriceRange,
                    minPriceRange,
                    brands
                } = data.data;

                setDefaultSizes(sizes);
                setDefaultBrands(brands);

                if(!skipPriceRange) {
                    setDefaultMaxPriceRange(maxPriceRange);
                    setDefaultMinPriceRange(minPriceRange);
                }
            })
            .catch((err) => console.error('err :', err))
            .finally(() => store.dispatch(closePreloaderAction()));
    }

    return (
        <Row>
            {isMobile && <ShowHideFiltersButton showHideFilters={showHideFilters} isFiltersShown={isFiltersShown} selectedFiltersCount={selectedFiltersCount} />}
            {isFiltersShown &&
                <>
                    <Col xs="12">
                        <PriceRanceFilter
                            defaultMinPriceRange={defaultMinPriceRange}
                            defaultMaxPriceRange={defaultMaxPriceRange}
                            setFilters={setFilters}
                            setPage={setPage}
                        />
                    </Col>
                    {productType === 'shoe' &&
                        <Col xs="12">
                            <ShoeTypeFilter
                                selectedShoeType={filters.selectedShoeType}
                                setFilters={setFilters}
                                setPage={setPage}
                            />
                        </Col>
                    }
                    <Col xs="6" md="12">
                        <SizeFilter selectedSizes={filters.selectedSizes} setFilters={setFilters} sizes={defaultSizes}
                            setPage={setPage}/>
                    </Col>
                    <Col xs="6" md="12">
                        <BrandFilter selectedBrands={filters.selectedBrands}  setFilters={setFilters} brands={defaultBrands}
                            setPage={setPage}/>
                    </Col>
                    {selectedFiltersCount 
                        ? <Col xs="12" className="text-center">
                                <BaseButton
                                    className="mb-2 clear-filters"
                                    text="Изчисти филтри"
                                    onClick={clearFilters}
                                />
                        </Col>
                        : <></>
                    }
                    {isMobile && <ShowHideFiltersButton showHideFilters={showHideFilters} isFiltersShown={isFiltersShown} selectedFiltersCount={selectedFiltersCount} />}
                    
                </>
            }
            {/* if selectedFiltersCount */}
            {isMobile && <FiltersGuideButton showHideFilters={showHideFilters} selectedFiltersCount={selectedFiltersCount}/>}
        </Row>
    )
}

ProductFilters.propTypes = {
    getProductsApi: PropTypes.func.isRequired,
    productType: PropTypes.string.isRequired
};

export default ProductFilters;