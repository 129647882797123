import React, { useEffect } from 'react';

import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { toastifySelector } from '../../../store/selectors';

function Toastify() {
  const message = useSelector(toastifySelector);

  useEffect(() => {
    if(message.length) {
      toast.success(message, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
    }
  }, [message]);

  return (
    <ToastContainer/>
  );
}

export default Toastify;