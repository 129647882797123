
import React from 'react';

import { Row, Col, FormGroup, Container, Label, Input } from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from 'formik';

import BaseButton from '../../../common/Buttons/BaseButton';
import { sendOrder } from "../../../../api";
import store from '../../../../store/store';
import { openPreloaderAction, closePreloaderAction, setCartProductsAction } from '../../../../store/actions';
 
import './style.scss'; 

const CartForm = ({ productsArticleNumbers, setIsOrderCompleted }) => { 
  const CartFormValidationSchema = Yup.object().shape({
    name: Yup.string()
      .required('Required'),
    phone: Yup.string()
      .required('Required'),
 });

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      city: '',
      courier: 'econt',
      address: '',
      additionalText: '',
      articleNumbers: productsArticleNumbers
    },
    onSubmit: values => {
      store.dispatch(openPreloaderAction())
        let sendOrderPayload = values;
        sendOrderPayload['articleNumbers'] = productsArticleNumbers;

      sendOrder(sendOrderPayload)
        .then((data) => {
          setIsOrderCompleted(true);
          store.dispatch(setCartProductsAction([]));
          window.localStorage.removeItem('cartProducts');
        })
        .catch((err) => console.error('err :', err))
        .finally(() => store.dispatch(closePreloaderAction()));
    },
    validationSchema: CartFormValidationSchema
  });
  
  return (
    <form
      onSubmit={formik.handleSubmit}
      className='cart-form'
    >
        <Container>
          <Row>
            <Col xs={12} xl={{offset: 2,size: 8}}>
              <Row>
                <Col>
                  <h2 className='text-center'>Завърши поръчката си</h2>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={8}>
                  <FormGroup>
                    <Label htmlFor="name">Имена*</Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      className={`${formik.touched.name && formik.errors.name ? 'required-input' : ''}`}
                    />
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label htmlFor="phone">Телефон*</Label>
                    <Input
                      id="phone"
                      name="phone"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      className={`${formik.touched.phone && formik.errors.phone ? 'required-input' : ''}`}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={6} md={4}>
                  <FormGroup>
                    <Label htmlFor="city">Град</Label>
                    <Input
                      id="city"
                      name="city"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.city}
                    />
                  </FormGroup>
                </Col>
                <Col xs={6} md={4}>
                  <FormGroup>
                    <Label for="courier">Куриер</Label>
                    <Input type="select"name="courier" onChange={formik.handleChange} id="courier">
                      <option value="econt">Еконт</option>
                      <option value="speedy">Спиди</option>
                      <option value="speedy">Лично предаване</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col xs={12} md={4}>
                  <FormGroup>
                    <Label htmlFor="address">Адрес на куриер</Label>
                    <Input
                      id="ciaddressty"
                      name="address"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.address}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label for="additionalText">Допълнителни пояснения</Label>
                    <Input
                      id="additionalText"
                      name="additionalText"
                      type="textarea"
                      onChange={formik.handleChange}
                      value={formik.values.additionalText}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className='text-center'>
                  <BaseButton text="завърши поръчка" type="submit"/>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
    </form>
  );
};

export default CartForm;