import React from "react";
import { Row, Col } from "reactstrap";
import './style.scss'; 

export default function SubHeadingText  () {
    return (
        <Row className="align-items-center sub-heading-text text-center align-center">
            <Col className="d-flex justify-space-between">
                <h3>Безплатно обслужване на всички ски и сноуборди!</h3>
                <h3>Направете запитване и в зависимост от състоянието на продукта можем да предложим допълнителна отстъпка.</h3>
                {/* <h3>Всички ски и сноуборди се ваксират и кантовете им се заточват, като тези услуги са включени в цената.</h3> */}
            </Col>
        </Row>
    )
}
