import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "reactstrap";
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import CartItem from "./CartItem";
import { openPreloaderAction, closePreloaderAction } from '../../../store/actions';
import store from '../../../store/store';
import { isPreloaderLoadingSelector } from '../../../store/selectors';
import ProductNotFound from '../../common/ProductSections/ProductNotFound';
import CartForm from "./CartForm";
import LightBackground from "../../hoc/LightBackground";
import DiscountPrice from "../../common/ProductSections/DiscountPrice";
import { scrollToTop } from '../../../helpers';

import './style.scss'; 

export default function Cart () {
    const [ storageCartProducts, setStorageCartProducts ] = useState(JSON.parse(window.localStorage.getItem('cartProducts')) || []);
    const [ products, setProducts ] = useState([]);
    const isPreloaderLoading = useSelector(isPreloaderLoadingSelector);
    const [ isOrderCompleted, setIsOrderCompleted ] = useState(false);

    useEffect(() => {
        scrollToTop();
    },[])

    useEffect(() => {
        store.dispatch(openPreloaderAction());

        const cartProductsUrls = storageCartProducts && storageCartProducts.length && storageCartProducts.map((articleNumber) => `https://api.skiburgas.com/product/${articleNumber}`);

        if(cartProductsUrls) {
            Promise.all(cartProductsUrls.map(url => fetch(url).then(r => r.json())))
            .then((data) => setProducts(data))
            .catch(error => console.error(error))
            .finally(() => store.dispatch(closePreloaderAction()));
        } else {
            store.dispatch(closePreloaderAction())
        }

    },[storageCartProducts])

    const totalDiscountPrice = products.map((product) => product.discount_price).reduce((a, b) => a + b, 0);
    const totalPrice = products.map((product) => product.price).reduce((a, b) => a + b, 0);
    const productsArticleNumbers = products.map((product) => product.article_number);

    return (
        <>
            {isOrderCompleted
                ? <Container className="completed-order">
                    <Row>
                        <Col className="text-center mt-5">
                            <FontAwesomeIcon icon={faCheckCircle}/>
                            <h2>Успешно направихте поръчка.</h2>
                            <h4>Очаквайте обаждане!</h4>
                        </Col>
                    </Row>
                </Container>
                : <>
                    <Container className="cart-page mt-4">
                        <Row>
                            <Col xs="12" xl={{offset: 2,size: 8}}>
                            <h2>Количка за пазаруване</h2>
                                {isPreloaderLoading
                                    ? <></>
                                    : products.length 
                                        ? products
                                            .map((product, i) => {
                                                    return <CartItem
                                                        key={`${i}-${product.article_number}`}
                                                        product={product}
                                                        setProducts={setProducts}
                                                    />
                                        })
                                        : <ProductNotFound isCart={true}/>
                                }
                            </Col>

                            {products.length && products
                                ? <Col xs="12" className="mt-5">
                                    <DiscountPrice big subText="Общо :" discountPrice={totalDiscountPrice} price={totalPrice}/>
                                    {/* <h3 className="additional-discount">До края на ноември 10% отстъпка върху общата сума</h3> */}
                                </Col>
                                : <></>
                            }
                        </Row>
                    </Container>
                    <Container fluid className="mb-3 p-0 mt-5">
                        {products.length && products
                            ? <LightBackground>
                                <CartForm productsArticleNumbers={productsArticleNumbers} setIsOrderCompleted={setIsOrderCompleted}/>
                            </LightBackground>
                            : <></>
                        }
                    </Container>
             </>}
        </>
    )
}