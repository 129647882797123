import React from "react";
import PropTypes from 'prop-types';
import FiltersIcon from "../../../../images/Icons/FiltersIcon/FiltersIcon";
import './style.scss'; 

function FiltersGuideButton ({ selectedFiltersCount, showHideFilters }) {
    return (
      <div onClick={showHideFilters} className="filters-guide-btn">
        <span className="filters-count">{selectedFiltersCount}</span>
          <FiltersIcon/>
      </div>
    )
}

FiltersGuideButton.propTypes = {
  selectedFiltersCount: PropTypes.number.isRequired,
  showHideFilters: PropTypes.func.isRequired
};

export default FiltersGuideButton;